define('ui/components/machine/driver-amazonec2/component', ['exports', 'ember', 'ui/mixins/driver'], function (exports, _ember, _uiMixinsDriver) {

  var RANCHER_TAG = 'rancher-ui';
  var RANCHER_GROUP = 'rancher-machine';
  var RANCHER_INGRESS_RULES = [
  // Docker machine creates these ports if we don't,
  // but explodes with race coditions if try to deploy 2 hosts simultaneously and they both want to create it.
  // So we'll just have the UI create them up front.
  // SSH, for docker-machine to install Docker
  {
    FromPort: 22,
    ToPort: 22,
    CidrIp: '0.0.0.0/0',
    IpProtocol: 'tcp'
  }, {
    FromPort: 2376,
    ToPort: 2376,
    CidrIp: '0.0.0.0/0',
    IpProtocol: 'tcp'
  },

  // Rancher IPSec
  {
    FromPort: 500,
    ToPort: 500,
    CidrIp: '0.0.0.0/0',
    IpProtocol: 'udp'
  }, {
    FromPort: 4500,
    ToPort: 4500,
    CidrIp: '0.0.0.0/0',
    IpProtocol: 'udp'
  },

  // Rancher VXLAN
  //{
  //  FromPort: 4789,
  //  ToPort: 4789,
  //  SourceSecurityGroupName: RANCHER_GROUP,
  //  IpProtocol: 'udp'
  //},

  // MTU Path discovery
  // shouldiblockicmp.com
  {
    FromPort: -1,
    ToPort: -1,
    CidrIp: '0.0.0.0/0',
    IpProtocol: 'icmp'
  }];

  var INSTANCE_TYPES = [{
    group: 'T2 - Burstable',
    name: 't2.nano'
  }, {
    group: 'T2 - Burstable',
    name: 't2.micro'
  }, {
    group: 'T2 - Burstable',
    name: 't2.small'
  }, {
    group: 'T2 - Burstable',
    name: 't2.medium'
  }, {
    group: 'T2 - Burstable',
    name: 't2.large'
  }, {
    group: 'T2 - Burstable',
    name: 't2.xlarge'
  }, {
    group: 'T2 - Burstable',
    name: 't2.2xlarge'
  }, {
    group: 'T3 - Burstable',
    name: 't3.nano'
  }, {
    group: 'T3 - Burstable',
    name: 't3.micro'
  }, {
    group: 'T3 - Burstable',
    name: 't3.small'
  }, {
    group: 'T3 - Burstable',
    name: 't3.medium'
  }, {
    group: 'T3 - Burstable',
    name: 't3.large'
  }, {
    group: 'T3 - Burstable',
    name: 't3.xlarge'
  }, {
    group: 'T3 - Burstable',
    name: 't3.2xlarge'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5.large'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5.xlarge'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5.2xlarge'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5.4xlarge'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5.12xlarge'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5.24xlarge'
  }, {
    group: 'M4 - General Purpose',
    name: 'm4.large'
  }, {
    group: 'M4 - General Purpose',
    name: 'm4.xlarge'
  }, {
    group: 'M4 - General Purpose',
    name: 'm4.2xlarge'
  }, {
    group: 'M4 - General Purpose',
    name: 'm4.4xlarge'
  }, {
    group: 'M4 - General Purpose',
    name: 'm4.10xlarge'
  }, {
    group: 'M4 - General Purpose',
    name: 'm4.16xlarge'
  }, {
    group: 'M3 - General Purpose',
    name: 'm3.medium'
  }, {
    group: 'M3 - General Purpose',
    name: 'm3.large'
  }, {
    group: 'M3 - General Purpose',
    name: 'm3.xlarge'
  }, {
    group: 'M3 - General Purpose',
    name: 'm3.2xlarge'
  }, {
    group: 'C5 - High CPU',
    name: 'c5.large'
  }, {
    group: 'C5 - High CPU',
    name: 'c5.xlarge'
  }, {
    group: 'C5 - High CPU',
    name: 'c5.2xlarge'
  }, {
    group: 'C5 - High CPU',
    name: 'c5.4xlarge'
  }, {
    group: 'C5 - High CPU',
    name: 'c5.9xlarge'
  }, {
    group: 'C5 - High CPU',
    name: 'c5.18xlarge'
  }, {
    group: 'C4 - High CPU',
    name: 'c4.large'
  }, {
    group: 'C4 - High CPU',
    name: 'c4.xlarge'
  }, {
    group: 'C4 - High CPU',
    name: 'c4.2xlarge'
  }, {
    group: 'C4 - High CPU',
    name: 'c4.4xlarge'
  }, {
    group: 'C4 - High CPU',
    name: 'c4.8xlarge'
  }, {
    group: 'C3 - High CPU',
    name: 'c3.large'
  }, {
    group: 'C3 - High CPU',
    name: 'c3.xlarge'
  }, {
    group: 'C3 - High CPU',
    name: 'c3.2xlarge'
  }, {
    group: 'C3 - High CPU',
    name: 'c3.4xlarge'
  }, {
    group: 'C3 - High CPU',
    name: 'c3.8xlarge'
  }, {
    group: 'R3 - High Memory',
    name: 'r3.large'
  }, {
    group: 'R3 - High Memory',
    name: 'r3.xlarge'
  }, {
    group: 'R3 - High Memory',
    name: 'r3.2xlarge'
  }, {
    group: 'R3 - High Memory',
    name: 'r3.4xlarge'
  }, {
    group: 'R3 - High Memory',
    name: 'r3.8xlarge'
  }, {
    group: 'R4 - High Memory',
    name: 'r4.large'
  }, {
    group: 'R4 - High Memory',
    name: 'r4.xlarge'
  }, {
    group: 'R4 - High Memory',
    name: 'r4.2xlarge'
  }, {
    group: 'R4 - High Memory',
    name: 'r4.4xlarge'
  }, {
    group: 'R4 - High Memory',
    name: 'r4.8xlarge'
  }, {
    group: 'R4 - High Memory',
    name: 'r4.16xlarge'
  }, {
    group: 'R5 - High Memory Optimized',
    name: 'r5.large'
  }, {
    group: 'R5 - High Memory Optimized',
    name: 'r5.xlarge'
  }, {
    group: 'R5 - High Memory Optimized',
    name: 'r5.2xlarge'
  }, {
    group: 'R5 - High Memory Optimized',
    name: 'r5.4xlarge'
  }, {
    group: 'R5 - High Memory Optimized',
    name: 'r5.12xlarge	'
  }, {
    group: 'R5 - High Memory Optimized',
    name: 'r5.24xlarge	'
  }, {
    group: 'R5D - High Memory Optimized & Local Storage',
    name: 'r5d.large'
  }, {
    group: 'R5D - High Memory Optimized & Local Storage',
    name: 'r5d.xlarge'
  }, {
    group: 'R5D - High Memory Optimized & Local Storage',
    name: 'r5d.2xlarge'
  }, {
    group: 'R5D - High Memory Optimized & Local Storage',
    name: 'r5d.4xlarge'
  }, {
    group: 'R5D - High Memory Optimized & Local Storage',
    name: 'r5d.12xlarge'
  }, {
    group: 'R5D - High Memory Optimized & Local Storage',
    name: 'r5d.24xlarge'
  }, {
    group: 'D2 - High Density Storage',
    name: 'd2.xlarge'
  }, {
    group: 'D2 - High Density Storage',
    name: 'd2.2xlarge'
  }, {
    group: 'D2 - High Density Storage',
    name: 'd2.4xlarge'
  }, {
    group: 'D2 - High Density Storage',
    name: 'd2.8xlarge'
  }, {
    group: 'I3 - High I/O Storage',
    name: 'i3.large'
  }, {
    group: 'I3 - High I/O Storage',
    name: 'i3.xlarge'
  }, {
    group: 'I3 - High I/O Storage',
    name: 'i3.2xlarge'
  }, {
    group: 'I3 - High I/O Storage',
    name: 'i3.4xlarge'
  }, {
    group: 'I3 - High I/O Storage',
    name: 'i3.8xlarge'
  }, {
    group: 'I3 - High I/O Storage',
    name: 'i3.16xlarge'
  }, {
    group: 'F1 - FPGA',
    name: 'f1.2xlarge'
  }, {
    group: 'F1 - FPGA',
    name: 'f1.16xlarge'
  }, {
    group: 'G3 - GPU',
    name: 'g3.4xlarge'
  }, {
    group: 'G3 - GPU',
    name: 'g3.8xlarge'
  }, {
    group: 'G3 - GPU',
    name: 'g3.16xlarge'
  }, {
    group: 'P2 - GPU',
    name: 'p2.xlarge'
  }, {
    group: 'P2 - GPU',
    name: 'p2.8xlarge'
  }, {
    group: 'P2 - GPU',
    name: 'p2.16xlarge'
  }, {
    group: 'X1 - Really High Memory',
    name: 'x1.16xlarge'
  }, {
    group: 'X1 - Really High Memory',
    name: 'x1.32xlarge'
  }];

  // These need to match the supported list in docker-machine:
  // https://github.com/docker/machine/blob/master/drivers/amazonec2/region.go
  var REGIONS = ["ap-northeast-1", "ap-northeast-2", "ap-southeast-1", "ap-southeast-2", "ap-south-1", "ca-central-1", "cn-north-1", "eu-west-1", "eu-west-2", "eu-west-3", "eu-central-1", "sa-east-1", "us-east-1", "us-east-2", "us-west-1", "us-west-2", "us-gov-west-1"];

  function nameFromResource(r, idField) {
    var id = r[idField];
    var out = id;

    if (r && r.Tags && r.Tags.length) {
      var match = r.Tags.filterBy('Key', 'Name')[0];
      if (match) {
        out = match.Value + ' (' + id + ')';
      }
    }

    return out;
  }

  exports['default'] = _ember['default'].Component.extend(_uiMixinsDriver['default'], {
    prefs: _ember['default'].inject.service(),
    driverName: 'amazonec2',
    model: null,
    amazonec2Config: _ember['default'].computed.alias('model.amazonec2Config'),
    intl: _ember['default'].inject.service(),

    clients: null,
    allSubnets: null,
    allSecurityGroups: null,
    selectedSecurityGroup: null,
    defaultSecurityGroup: null,
    defaultSecurityGroupName: RANCHER_GROUP,
    whichSecurityGroup: 'default',
    isCustomSecurityGroup: _ember['default'].computed.equal('whichSecurityGroup', 'custom'),
    instanceTypes: INSTANCE_TYPES,
    regionChoices: REGIONS,

    step: 1,
    isStep1: _ember['default'].computed.equal('step', 1),
    isStep2: _ember['default'].computed.equal('step', 2),
    isStep3: _ember['default'].computed.equal('step', 3),
    isStep4: _ember['default'].computed.equal('step', 4),
    isStep5: _ember['default'].computed.equal('step', 5),
    isStep6: _ember['default'].computed.equal('step', 6),
    isStep7: _ember['default'].computed.equal('step', 7),
    isGteStep3: _ember['default'].computed.gte('step', 3),
    isGteStep4: _ember['default'].computed.gte('step', 4),
    isGteStep5: _ember['default'].computed.gte('step', 5),
    isGteStep6: _ember['default'].computed.gte('step', 6),
    isGteStep7: _ember['default'].computed.gte('step', 7),

    bootstrap: function bootstrap() {
      var pref = this.get('prefs.amazonec2') || {};
      var config = this.get('store').createRecord({
        type: 'amazonec2Config',
        region: 'us-west-2',
        instanceType: 't2.micro',
        securityGroup: 'rancher-machine',
        zone: 'a',
        rootSize: 16,
        accessKey: pref.accessKey || '',
        secretKey: pref.secretKey || ''
      });

      this.set('model', this.get('store').createRecord({
        type: 'host',
        amazonec2Config: config
      }));
    },

    init: function init() {
      this._super.apply(this, arguments);

      this.set('editing', false);
      this.set('clients', _ember['default'].Object.create());
      this.set('allSubnets', []);

      var cur = this.get('amazonec2Config.securityGroup');

      if (cur === RANCHER_GROUP) {
        this.setProperties({
          whichSecurityGroup: 'default',
          selectedSecurityGroup: null
        });
      } else {
        this.setProperties({
          whichSecurityGroup: 'custom',
          selectedSecurityGroup: cur
        });
      }
    },

    willDestroyElement: function willDestroyElement() {
      this.setProperties({
        step: 1,
        machineId: null,
        clients: null,
        allSubnets: null,
        allSecurityGroups: null,
        whichSecurityGroup: 'default'
      });
    },

    stepDidChange: (function () {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        document.body.scrollTop = document.body.scrollHeight;
      });
    }).observes('context.step'),

    selectedSecurityGroupChanged: _ember['default'].observer('whichSecurityGroup', 'isStep5', function () {
      if (this.get('isStep5') && this.get('whichSecurityGroup') === 'custom') {
        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          this.initMultiselect();
        });
      }
    }),

    actions: {
      awsLogin: function awsLogin() {
        var _this = this;

        var self = this;
        this.set('errors', null);
        this.set('step', 2);

        this.set('amazonec2Config.accessKey', (this.get('amazonec2Config.accessKey') || '').trim());
        this.set('amazonec2Config.secretKey', (this.get('amazonec2Config.secretKey') || '').trim());

        var subnets = [];
        var rName = this.get('amazonec2Config.region');
        var ec2 = new AWS.EC2({
          accessKeyId: this.get('amazonec2Config.accessKey'),
          secretAccessKey: this.get('amazonec2Config.secretKey'),
          region: rName
        });

        var vpcNames = {};

        ec2.describeVpcs({}, function (err, vpcs) {
          if (err) {
            var errors = self.get('errors') || [];
            errors.pushObject(err);
            _this.set('errors', errors);
            _this.set('step', 1);
            return;
          }

          vpcs.Vpcs.forEach(function (vpc) {
            vpcNames[vpc.VpcId] = nameFromResource(vpc, 'VpcId');
          });

          ec2.describeSubnets({}, function (err, data) {
            if (err) {
              var errors = self.get('errors') || [];
              errors.pushObject(err);
              _this.set('errors', errors);
              _this.set('step', 1);
              return;
            }

            _this.get('clients').set(rName, ec2);

            data.Subnets.forEach(function (subnet) {
              if ((subnet.State || '').toLowerCase() !== 'available') {
                return;
              }

              subnets.pushObject(_ember['default'].Object.create({
                subnetName: nameFromResource(subnet, 'SubnetId'),
                subnetId: subnet.SubnetId,
                vpcName: vpcNames[subnet.VpcId] || subnet.VpcId,
                vpcId: subnet.VpcId,
                zone: subnet.AvailabilityZone,
                region: rName
              }));
            });

            _this.set('allSubnets', subnets);
            _this.set('step', 3);
          });
        });
      },

      selectSubnet: function selectSubnet() {
        var _this2 = this;

        this.set('errors', null);

        if (!this.get('selectedZone')) {
          this.set('errors', ['Select an Availability Zone']);
          return;
        }

        if (!this.get('selectedSubnet')) {
          this.set('errors', ['Select a VPC or Subnet']);
          return;
        }

        this.set('step', 4);

        var ec2 = this.get('clients').get(this.get('amazonec2Config.region'));
        var filter = { Name: 'vpc-id', Values: [this.get('amazonec2Config.vpcId')] };

        ec2.describeSecurityGroups({ Filters: [filter] }, function (err, data) {
          if (err) {
            _this2.set('errors', [err]);
            _this2.set('step', 3);
            return;
          }

          var groups = [];
          var defaultGroup = null;

          data.SecurityGroups.forEach(function (group) {
            var tags = {};

            // Skip launch-wizard groups
            if ((group.GroupName || '').match(/^launch-wizard-.*$/)) {
              return;
            }

            (group.Tags || []).forEach(function (tag) {
              tags[tag.Key] = tag.Value;
            });

            var obj = {
              id: group.GroupId,
              name: group.GroupName,
              description: group.Description,
              isDefault: group.GroupName === _this2.get('defaultSecurityGroupName'),
              isRancher: typeof tags[RANCHER_TAG] !== 'undefined'
            };

            groups.push(obj);

            if (obj.isDefault && !defaultGroup) {
              defaultGroup = obj;
            }
          });

          _this2.set('step', 5);
          _this2.set('allSecurityGroups', groups);
          _this2.set('defaultSecurityGroup', defaultGroup);
        });
      },

      multiSecurityGroupSelect: function multiSecurityGroupSelect() {
        var options = Array.prototype.slice.call(_ember['default'].$('.existing-security-groups')[0], 0);
        var selectedOptions = [];

        options.filterBy('selected', true).forEach(function (cap) {
          return selectedOptions.push(cap.value);
        });

        this.set('selectedSecurityGroup', selectedOptions);
      },

      selectSecurityGroup: function selectSecurityGroup() {
        this.set('errors', null);

        var self = this;
        var ec2 = this.get('clients').get(this.get('amazonec2Config.region'));

        if (this.get('isCustomSecurityGroup')) {
          this.set('amazonec2Config.securityGroup', this.get('selectedSecurityGroup'));
          done();
        } else {
          this.set('step', 6);
          this.set('amazonec2Config.securityGroup', this.get('defaultSecurityGroupName'));
          var group = this.get('defaultSecurityGroup');
          if (group) {
            if (group.isRancher) {
              this.set('amazonec2Config.securityGroup', group.name);
              done();
            } else {
              addRules(group.id, done);
            }
          } else {
            ec2.createSecurityGroup({
              GroupName: this.get('defaultSecurityGroupName'),
              Description: this.get('settings.appName') + ' default security group',
              VpcId: this.get('amazonec2Config.vpcId')
            }, function (err, data) {
              if (err) {
                return done(err);
              } else {
                return addRules(data.GroupId, done);
              }
            });
          }
        }

        function addRules(groupId, cb) {
          async.each(RANCHER_INGRESS_RULES, function (item, cb) {
            var params = JSON.parse(JSON.stringify(item)); // Don't change the original
            params.GroupId = groupId;
            ec2.authorizeSecurityGroupIngress(params, cb);
          }, function (err) {
            if (err) {
              return cb(err);
            }

            ec2.createTags({
              Resources: [groupId],
              Tags: [{ Key: RANCHER_TAG, Value: self.get('app.version') }]
            }, cb);
          });
        }

        function done(err) {
          if (err) {
            this.set('errors', [err]);
            self.set('step', 5);
          } else {
            self.set('step', 7);
          }
        }
      }
    },

    initMultiselect: function initMultiselect() {
      var view = this;

      var opts = {
        maxHeight: 200,
        buttonClass: 'btn btn-default',
        buttonWidth: '100%',

        templates: {
          li: '<li><a tabindex="0"><label></label></a></li>'
        },

        buttonText: function buttonText(options /*, select*/) {
          var label = 'Security Groups: ';
          if (options.length === 0) {
            label += 'None';
          } else if (options.length === 1) {
            label += $(options[0]).text();
          } else {
            label += options.length + ' Selected';
          }

          return label;
        },

        onChange: function onChange() /*option, checked*/{
          var self = this;
          var options = $('option', this.$select);
          var selectedOptions = this.getSelected();
          var allOption = $('option[value="ALL"]', this.$select)[0];

          var isAll = $.inArray(allOption, selectedOptions) >= 0;

          if (isAll) {
            options.each(function (k, option) {
              var $option = $(option);
              if (option !== allOption) {
                self.deselect($(option).val());
                $option.prop('disabled', true);
                $option.parent('li').addClass('disabled');
              }
            });

            // @TODO Figure out why deslect()/select() doesn't fix the state in the ember object and remove this hackery...
            var ary = view.get('instance.' + (this.$select.hasClass('select-cap-add') ? 'capAdd' : 'capDrop'));
            ary.clear();
            ary.pushObject('ALL');
          } else {
            options.each(function (k, option) {
              var $option = $(option);
              $option.prop('disabled', false);
              $option.parent('li').removeClass('disabled');
            });
          }

          this.$select.multiselect('refresh');
        }
      };

      this.$('.existing-security-groups').multiselect(opts);
    },
    selectedZone: _ember['default'].computed('amazonec2Config.{region,zone}', {
      get: function get() {
        var config = this.get('amazonec2Config');
        if (config.get('region') && config.get('zone')) {
          return config.get('region') + config.get('zone');
        } else {
          return null;
        }
      },

      set: function set(key, val) {
        var config = this.get('amazonec2Config');
        config.setProperties({
          region: val.substr(0, val.length - 1),
          zone: val.substr(val.length - 1)
        });

        var selectedSubnet = this.get('selectedSubnet');

        if (this.get('subnetChoices').filterBy('value', selectedSubnet).length === 0) {
          config.setProperties({
            region: val.substr(0, val.length - 1),
            zone: val.substr(val.length - 1),
            vpcId: null,
            subnetId: null
          });
        }

        if (config.get('region') && config.get('zone')) {
          return config.get('region') + config.get('zone');
        } else {
          return null;
        }
      }
    }),

    zoneChoices: (function () {
      var choices = (this.get('allSubnets') || []).map(function (subnet) {
        return subnet.get('zone');
      }).sort().uniq();
      if (choices.length) {
        this.set('selectedZone', choices[0]);
      }
      return choices;
    }).property('allSubnets.@each.{zone}'),

    subnetChoices: (function () {
      var out = [];
      var seenVpcs = [];

      (this.get('allSubnets') || []).filterBy('zone', this.get('selectedZone')).forEach(function (subnet) {
        var vpcName = subnet.get('vpcName');
        var vpcId = subnet.get('vpcId');
        var subnetId = subnet.get('subnetId');
        var subnetName = subnet.get('subnetName');

        if (seenVpcs.indexOf(vpcId) === -1) {
          seenVpcs.pushObject(vpcId);
          out.pushObject({
            sortKey: vpcId,
            label: vpcName,
            value: vpcId,
            isVpc: true
          });
        }

        out.pushObject({
          sortKey: vpcId + ' ' + subnetName,
          label: subnetName,
          value: subnetId,
          isVpc: false
        });
      });

      return out.sortBy('sortKey');
    }).property('selectedZone', 'allSubnets.@each.{subnetId,vpcId,zone}'),

    selectedSubnet: _ember['default'].computed('amazonec2Config.{subnetId,vpcId}', {
      set: function set(key, val) {
        var config = this.get('amazonec2Config');
        if (arguments.length > 1) {
          if (val && val.length) {
            if (val.indexOf('vpc-') === 0) {
              config.setProperties({
                vpcId: val,
                subnetId: null
              });
            } else {
              var subnet = this.subnetById(val);
              config.setProperties({
                vpcId: subnet.vpcId,
                subnetId: subnet.subnetId
              });
            }
          } else {
            config.setProperties({
              vpcId: null,
              subnetId: null
            });
          }
        }

        return config.get('subnetId') || config.get('vpcId');
      },

      get: function get() {
        var config = this.get('amazonec2Config');
        return config.get('subnetId') || config.get('vpcId');
      }
    }),

    subnetById: function subnetById(id) {
      return (this.get('allSubnets') || []).filterBy('subnetId', id)[0];
    },

    validate: function validate() {
      var _this3 = this;

      var errors = [];
      var config = this.get('amazonec2Config');

      if (!this.get('nameParts.prefix') && !this.get('nameParts.name')) {
        errors.push('Name is required');
      }

      if (Object.keys(config.get('tags'))) {
        (function () {
          var tags = config.get('tags');

          Object.keys(tags).forEach(function (tag) {
            if (tag.indexOf(',') > -1 || tags[tag].indexOf(',') > -1) {
              errors.push(_this3.get('intl').t('machine.driverAmazon.tags.error', { tag: tag }));
            }
          });
        })();
      }

      this.set('errors', errors);
      return errors.length === 0;
    }
  });
});